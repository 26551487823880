
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetStaticPropsContext } from 'next';
import DeprecatedLayout from 'components/DeprecatedLayout';
import { getV1Props } from 'lib/page-props/getV1Props';
import { getV2Props } from 'lib/page-props/getV2Props';
import LayoutComponent from '_components/LayoutComponent';
import { fontFamilyMap } from './_app';
import PageNotFound from '_components/PageNotFound';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import FaviconHead from 'components/FaviconHead';
interface NotFoundPageProps {
    locale: 'th' | 'en' | 'vi' | 'id' | 'en-PH' | 'en-SG' | 'en-MY';
    tenant: string;
    page?: any;
    layout?: any;
    settings?: any;
}
export default function NotFoundPage(props: NotFoundPageProps) {
    const { t } = useTranslation('layout');
    if (props.tenant !== 'asiadirect' &&
        (props.locale === 'th' || props.locale === 'en')) {
        return (<DeprecatedLayout {...props}>
        <PageNotFound />
        <FaviconHead tenant={props.tenant}/>
      </DeprecatedLayout>);
    }
    const { locale, layout, settings } = props;
    return (<div style={{ fontFamily: fontFamilyMap[locale] }} className={clsx(props.tenant === 'asiadirect' && 'theme-adb')}>
      <LayoutComponent tenant={props.tenant} navigations={layout.navigation.map((nav: any) => ({
            text: nav.title,
            url: nav.linkUrl,
            subcategory: nav.subcategory.map((sub: any) => ({
                text: sub.title,
                url: sub.link,
            })),
        }))} contact={{
            phone: settings.phone,
            openingHours: settings.openingTimes,
            lineUrl: settings.lineUrl,
            messengerUrl: settings.messengerUrl,
        }} footer={{
            content: t('footer.content'),
            links: layout.footer.links,
        }} locale={layout.locale || ''}>
        <PageNotFound tenant={props.tenant}/>
        <FaviconHead tenant={props.tenant}/>
      </LayoutComponent>
    </div>);
}
async function getStaticProps({ locale }: GetStaticPropsContext) {
    locale = locale || 'th';
    let props;
    if (locale === 'th' || locale === 'en') {
        props = await getV1Props(locale, ['th', 'en']);
    }
    else {
        props = await getV2Props(locale);
    }
    return {
        props,
        revalidate: process.env.REVALIDATE_MS
            ? parseInt(process.env.REVALIDATE_MS)
            : 60,
    };
}

    async function __Next_Translate__getStaticProps__195ef9570a7__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195ef9570a7__ as getStaticProps }
  