import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import Link from 'next/link';

export default function PageNotFound({ tenant }: { tenant?: string }) {
  const { t } = useTranslation('layout');

  return (
    <div className="flex-1 flex flex-col place-items-center min-h-[70vh] p-24">
      <Image
        width={424}
        height={336}
        src={
          tenant === 'asiadirect'
            ? '/images/asiadirect/page-not-found.png'
            : '/images/page-not-found.png'
        }
        alt="page_not_found"
      />

      <h2 className="text-primaryColor font-bold my-4">
        {t('notFound.title')}
      </h2>
      <span className="text-textColor text-center mb-[2.5rem]">
        {t('notFound.message')}
      </span>

      <Link href="/">
        <button className="bg-primaryColor hover:bg-buttonPrimaryBgHover text-white px-[2.5rem] py-[0.9375rem] font-bold uppercase rounded-[0.625rem]">
          {t('notFound.backToHome')}
        </button>
      </Link>
    </div>
  );
}
